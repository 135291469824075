<template>
  <div class="patch-item">
    <div class="patch-item__header">{{$t('selection.patchTitle')}}</div>
    <div class="patch-item__body">
      <div class="row form-group">
        <div class="col-xs-8">
          <input class="form-control" type="date" v-model="date">
        </div>
        <div class="col-xs-4">
          <input class="form-control" type="time" v-model="time">
        </div>
      </div>
      <textarea class="form-control" :placeholder="$t('selection.patchInputPlaceholder')" v-model="content"></textarea>
      <p class="patch-info">{{$t('image.tipTooSmall')}}</p>
      <div class="patch-item__images">
        <div class="patch-item__image" @click="deleteImage(index)"
             v-for="(image, index) in images" :key="image[0]">
          <div class="uploading-item" :data-orient="image[4]" :style="{backgroundImage: 'url(' + image[1] + ')'}"></div>
          <div class="patch-item__action"  role="button">
            <i class="fa fa-times" aria-hidden="true"></i>
          </div>
        </div>
        <div class="patch-item__image"
             v-for="image in tempImages" :key="image.timestamp">
          <div class="uploading-item" :data-orient="image.rotate" :style="{backgroundImage: 'url(' + image.image + ')'}"></div>
          <block :done="image.dataLoaded"></block>
        </div>
        <div class="patch-item__image upload-btn">
          <uploader @change="uploadImage" :mixed="true"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from 'src/api'
import { findIndex, last } from 'lodash'
import EXIF from 'exif-js'
import Block from 'src/components/common/LoadingBlock.vue'
const d = new Date()
const month = `0${d.getMonth() + 1}`.substr(-2)
const day = `0${d.getDate()}`.substr(-2)
const hours = `0${d.getHours()}`.substr(-2)
const minutes = `0${d.getMinutes()}`.substr(-2)
const date = `${d.getFullYear()}-${month}-${day}`
const time = `${hours}:${minutes}`
export default {
  name: '',
  components: { Block },
  data: () => ({
    date,
    time,
    content: '',
    title: '',
    images: [],
    tempImages: []
  }),
  computed: {},
  created () {},
  methods: {
    async uploadImage (images) {
      if (this.tempImages.length) {
        this.$toast({
          message: '当前存在上传内容'
        })
        return
      }
      const albums = []
      console.time('处理图片')
      for (const image of images) {
        let temImage
        if (image.source) { // 可预览图片
          temImage = await this.readImageExif(image)
        } else {
          temImage = { source: image, rotate: 0 }
        }
        albums.push(temImage)
      }
      console.timeEnd('处理图片')

      const imgs = []
      // 图集上传
      albums.forEach((item, index) => {
        const timestamp = window.btoa(Date.now() + index * 100)
        const form = new FormData()
        imgs.push({ ...item, ...{ timestamp } })
        form.append('image', item.source)
        api.uploadImage(form).then(data => {
          const imgIndex = findIndex(this.tempImages, { timestamp })
          let { origin, hostname } = window.location

          if (/localhost|m\.ipastbook/g.test(hostname)) {
            origin = 'https://ipastbook.com'
          }
          data.image = /http?s:/.test(data.image) ? data.image : `${origin}${data.image}`
          const img = [data.imageId, data.image, false, timestamp, item.rotate]
          if (item.image) {
            img[1] = item.image
          }

          this.tempImages.splice(imgIndex, 1)

          // 图片上传顺序，按上传时间排序，成功之后拿当前图片去排序
          const arr = this.images.slice()
          const lastImages = last(arr) || []
          if (lastImages[3]) {
            for (const item in arr) {
              const image = arr[item]
              if (image[3]) { // 当前存在上传信息
                if (window.atob(image[3]) > window.atob(timestamp)) { // 元素
                  this.images.splice(item, 0, img)
                  break
                } else {
                  if (Number(item) === arr.length - 1) {
                    this.images.splice(item + 1, 0, img)
                    break
                  }
                }
              }
            }
          } else {
            this.images.push(img)
          }
        }).catch(e => {
          console.log(e)
          const activeIndex = findIndex(this.tempImages, { timestamp })
          this.tempImages.splice(activeIndex, 1)
        })
      })
      this.tempImages = imgs
    },
    // 读取图片原信息
    readImageExif (image) {
      return new Promise((resolve, reject) => {
        try {
          EXIF.getData(image.source, function () {
            const allMetaData = EXIF.getAllTags(this)
            image.rotate = allMetaData.Orientation || 0
            resolve(image)
          })
        } catch (e) {
          console.log(e)
          image.rotate = 0
          resolve(image)
        }
      })
    },
    deleteImage (index) {
      this.images.splice(index, 1)
    }
  }
}
</script>

<style type="text/scss" lang="scss" scoped>
  .patch-item {
    border: 1px solid #d9d9d9;
    background-color: #fff;
    border-radius: 4px;
    overflow: hidden;
    .patch-item__header{
      background-color: #f7f7f7;
      padding: 1rem;
      font-size: 16px;
    }
    .patch-item__body {
      padding: 1rem;
      background-color: #fff;
      textarea {
        margin: 1rem 0;
        min-height: 96px;
      }
    }
    .patch-item__images {
      display: flex;
      flex-wrap: wrap;
    }
    .uploading-item {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: {
        size: cover;
        repeat: no-repeat;
        position: 50%;
      }
    }
    .patch-item__image {
      width: 22%;
      padding-bottom: 22%;
      border-radius: 4px;
      position: relative;
      margin-right: 3%;
      margin-bottom: 1rem;
      overflow: hidden;
      background: {
        color: #eee;
        size: cover;
        repeat: no-repeat;
        position: 50%;
      }
      &.upload-btn {
        background: {
          color: white;
          size: auto;
          image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUAQMAAAC3R49OAAAABlBMVEUAAADd3d3swgqXAAAAAXRSTlMAQObYZgAAABZJREFUCFtjZPggwIgP/2f4iIIJqAcAuP0X1ZQ/+6oAAAAASUVORK5CYII=);
          repeat: no-repeat;
          position: 50%;
        };
        border: 1px dashed #eee;
        label {
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
        }
      }
      p {
        position: absolute;
        color: white;
        top: 50%;
        margin-top: -0.5rem;
        left: 0;
        width: 100%;
        text-align: center;
      }
    }
    .patch-item__action {
      position: absolute;
      top: 0;
      right: 0;
      width: 2.75rem;
      height: 2.75rem;
      line-height: 2rem;
      padding: 0 0 .75rem .75rem;
      background-color: rgba(183, 183, 183, 0.9);
      border-radius: 0 0 0 100px;
      text-align: center;
      color: white;
      font-size: 1.25rem;
    }
    input[type=date], input[type=time] {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
    }
    .patch-info {
      font-size: 14px;
      margin: 1rem 0;
    }
  }
</style>
