<template>
  <label class="file-label uploader" :for="fileId">
    <slot></slot>
    <input ref="files" type="file"
           accept="image/*"
           @change="uploadImage"
           :id="fileId">
  </label>
</template>

<script>
import { getBlob } from 'src/_utils/'
export default {
  name: '',
  props: {
    fileId: String,
    // 添加是否支持多张上传 mixed
    mixed: Boolean,
    limit: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {}
  },
  created () {},
  mounted () {
    if (this.mixed) {
      if (window.isAndroid) {
        return
      }
      this.$refs.files.setAttribute('multiple', 'multiple')
    }
  },
  methods: {
    async uploadImage (event) {
      let $fileList = null
      if (this.type !== 'single') {
        $fileList = Array.from(event.target.files)
      } else {
        $fileList = [event.target.files[0]]
      }
      event.target.value = ''
      if (window.FileReader) {
        const files = []
        for (const file of $fileList) {
          const image = await this.readDataAsUrl(file)
          files.push({ image, source: file })
        }
        this.$emit('change', files)
      } else {
        this.$toast({
          message: '您的设备不支持本地预览功能'
        })
        this.$emit('change', $fileList)
      }
    },
    readDataAsUrl (file) {
      const oFReader = new FileReader()
      oFReader.readAsDataURL(file)
      const _this = this
      return new Promise((resolve) => {
        oFReader.onload = (e) => {
          const data = e.target.result
          const Image = new window.Image()
          Image.src = data
          Image.onload = function () {
            if (Image.height < 480 || Image.width < 480) {
              _this.$toast({
                message: _this.$t('image.tipTooSmall'),
                position: 'bottom'
              })
            } else {
              const imageData = window.atob(data.split(',')[1])
              const buffer = new Uint8Array(imageData.length)
              for (let i = 0; i < imageData.length; i++) {
                buffer[i] = imageData.charCodeAt(i)
              }

              const blob = getBlob([buffer], { type: file.type })
              const urlCreator = window.URL || window.webkitURL
              const imageUrl = urlCreator.createObjectURL(blob)
              resolve(imageUrl)
            }
          }
        }
      })
    }
  }
}
</script>

<style type="text/scss" lang="scss">
.uploader {
  height: 100%;
  display: inline-block;
  position: relative;
  cursor: pointer;
}
</style>
